<template>
  <div class="text-right" style="margin-top: 0.239rem;">
    <div style="width: 100%; ">
      <div class="account">
        {{$t('transfer.contact_content5')}}
      </div>
      <div class="tishi">
        {{$t('transfer.contact_content6')}}
      </div>
      <div v-show="enterIDshow" class="enterIDss">
        <div class="enterID">
          {{$t('transfer.contact_content7')}}
        </div>
        <van-field v-model="userid" class="useridv" clearable type="digit" @input="userkong">
          <template #button>
            <img @click="lianxir" style="width: 0.4rem" src="../../assets/transfer/txl.png" alt="">
          </template>
        </van-field>
      </div>
      <div v-show="quantityShow" class="enterIDss">
        <div class="enterID">
          {{$t('transfer.contact_content11')}}
        </div>
        <van-field v-model="quantity" class="useridv" type="digit"></van-field>
      </div>
      <div v-if="!usershow">
        <div class="userInfobox flexs" style="padding-top: 0.2rem;">
          <div class="flexs" style="flex-grow: 1;">
            <van-image class="avatar" :src="userInfo.avatar" error-icon='https://image2.pocketliveapp.com/image/icon/static/def_icon.png' />
            <div class="userInfo">
              <div class="name">{{userInfo.username}}</div>
              <div class="userid">{{$i18n.locale=='ar'?userInfo.id+":ID":'ID:'+userInfo.id }}</div>
              <div class="flexs ">
                <img class="guoqi" :src="userInfo.areaUrl" alt="">
                <span class="diqus">{{userInfo.area}}</span>
              </div>
            </div>
          </div>
          <div class="record" @click="chuandi(userInfo)">{{$t('transfer.contact_content10')}}</div>
        </div>
        <div v-if="transferShow" class="flexs" style="margin-top: 0.598rem;">
          <img style="width: 0.34rem;" src="../../assets/zs.svg" alt="">
          <div class="myDiamond">{{$t('transfer.contact_content12')}}：</div>
          <div class="zsshu">{{myDiamond}}</div>
        </div>
      </div>
      <div v-if="usershow" :style="userid==''?'opacity: 0.4':''" class="nextStep" @click="searchUser">
        {{$t('transfer.contact_content8')}}</div>
      <div v-if="userhied" class="nextStep" @click="confirmUserinfo()">{{$t('transfer.contact_content33')}}</div>
      <div v-if="transferShow" :style="quantity==''?'opacity: 0.4':''" class="nextStep" @click="transfers">
        {{$t('transfer.contact_content33')}}</div>
      <div>
      </div>
    </div>
    <div>
      <van-popup v-model="showpopups" position="bottom" round>
        <div>
          <div class="passwordtitle">
            {{iPasswordShow?$t('transfer.contact_content14'):$t('transfer.contact_content19')}}
          </div>
          <div class="passwordcontent">
            <div class="titlebox">
              <div class="dian" style="margin-bottom: 0"></div>
              <div class="titleb">
                {{iPasswordShow?$t('transfer.contact_content15'):$t('transfer.contact_content19')}}
              </div>
            </div>
            <div class="paymentMethod">
              <van-field v-if="iPasswordShow" ref="refiPassword" type="text" v-model="iPassword" maxlength="9" :placeholder="$t('transfer.contact_content16')">
                <template #button v-if="iPassword!=''">
                  <img style="width: 0.25rem;margin-right: 0.33rem;" @click="qingkong(1)" src="../../assets/transfer/del.png" alt="">
                  <img style="width: 0.3rem;margin-right: 0.2rem;" @click="passwordShowh(1)" :src="pawsswordimg" alt="">
                </template>
              </van-field>
              <van-field v-else type="text" ref="refcPassword" v-model="cPassword" maxlength="9" :placeholder="$t('transfer.contact_content16')">
                <template #button v-if="cPassword!=''">
                  <img style="width: 0.25rem;margin-right: 0.33rem;" @click="qingkong(1)" src="../../assets/transfer/del.png" alt="">
                  <img style="width: 0.3rem;margin-right: 0.2rem;" @click="passwordShowh(2)" :src="pawsswordimg" alt="">
                </template>
              </van-field>
            </div>
            <div v-if="passworderr" class="passworderr">{{$t('transfer.contact_content18')}}</div>
            <div class="passwordtishi">
              {{$t('transfer.contact_content17')}}
            </div>
            <div v-if="iPasswordShow" class="nextStep" @click="pwsetup" style="margin-bottom: 1.19rem;" :style="iPassword==''?'opacity: 0.4':''">{{$t('transfer.contact_content8')}}</div>
            <div v-else class="nextStep" @click="Confirm" style="margin-bottom: 1.19rem;" :style="cPassword==''?'opacity: 0.4':''">{{$t('transfer.contact_content20')}}</div>
          </div>
        </div>
      </van-popup>
      <van-popup v-model="passwordyes" position="bottom" round>
        <div>
          <div class="passwordtitle">{{$t('transfer.contact_content14')}}</div>
          <div class="passwordcontent">
            <section class="wenzi3" id="zi11">
            </section>
            <div class="passwordtishi" style="color: #000000;margin: 0 0.8rem;text-align: center;">
              {{$t('transfer.contact_content32')}}
            </div>
            <div class="nextStep" @click="zzConfirm" style="margin-bottom: 1.19rem;">
              {{$t('transfer.contact_content20')}}</div>
          </div>
        </div>
      </van-popup>
      <van-popup v-model="zzConfirmShow" position="bottom" round>
        <div>
          <div class="passwordtitle">
            {{iPasswordShow?$t('transfer.contact_content22'):$t('transfer.contact_content19')}}</div>
          <div class="passwordcontent">
            <div class="titlebox" style="margin-bottom: 0">
              <div class="dian" style="margin-bottom: 0"></div>
              <div class="titleb">{{$t('transfer.contact_content23')}}</div>
            </div>
            <div class="flexs" style="flex-grow: 1;margin-top: 0.25rem;">
              <van-image class="avatar" :src="userInfo.avatar" error-icon='https://image2.pocketliveapp.com/image/icon/static/def_icon.png' />
              <div class="userInfo">
                <div class="name">{{userInfo.username}}</div>
                <div class="userid">{{$i18n.locale=='ar'?userInfo.id+":ID":'ID：'+userInfo.id }}</div>
                <div class="flexs "><img class="guoqi" :src="userInfo.areaUrl" alt=""><span class="diqus">{{userInfo.area}}</span>
                </div>
              </div>
            </div>
            <div class="titlebox" style="padding-top: 0.598rem;margin-bottom:0">
              <div class="dian" style="margin-bottom: 0"></div>
              <div class="titleb">{{$t('transfer.contact_content24')}}</div>
            </div>
            <div class="flexs" style="margin-top: 0.25rem;">
              <img style="width: 0.34rem;" src="../../assets/zs.svg" alt="">
              <div class="zhuanshis">{{quantity}}</div>
            </div>
            <div class="titlebox" style="padding-top: 0.598rem;margin-bottom:0">
              <div class="dian" style="margin-bottom: 0"></div>
              <div class="titleb">{{$t('transfer.contact_content15')}}</div>
            </div>
            <div class="paymentMethod" style="margin-top: 0.25rem;">
              <van-field type="text" v-model="password" ref="refpassword" maxlength="9" @input="qingkong(4)" :placeholder="$t('transfer.contact_content16')">
                <template #button v-if="password!=''">
                  <img style="width: 0.25rem;margin-right: 0.33rem;" @click="qingkong(3)" src="../../assets/transfer/del.png" alt="">
                  <img style="width: 0.3rem;margin-right: 0.2rem;" @click="passwordShowh(3)" :src="pawsswordimg" alt="">
                </template>
              </van-field>
            </div>
            <div v-if="zfpassworderr" class="passworderr">{{$t('transfer.contact_content27')}}</div>

            <div style="width: 100%;">
              <div class="pwtishi" st>
                {{$t('transfer.contact_content26')}}
              </div>
            </div>
            <div class="nextStep" @click="ConfirmTransfer" style="margin-bottom: 1.19rem;" :style="password==''?'opacity: 0.4':''">{{$t('transfer.contact_content25')}}</div>
          </div>
        </div>
        <div class="stopRepeat" v-if="stopRepeatFlag">
          <div class="loading"></div>
        </div>
      </van-popup>
      <van-popup v-model="transferyes" position="bottom" round>
        <div class="passwordtitle">{{$t('transfer.contact_content28')}}</div>
        <div class="passwordcontent">
          <section class="wenzi3" style="margin: 2.4rem 0;" id="zi11s">
          </section>
          <div class="nextStep" @click="transferyes=false" style="margin-bottom: 1.19rem;">
            {{$t('transfer.contact_content20')}}</div>
        </div>
      </van-popup>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import { Toast } from "vant";
import Cookies from 'js-cookie';
import show from '../../assets/transfer/show.png'
import hide from '../../assets/transfer/hide.png'
import language from '../../providers/CountryCode.json'

export default {
  name: "App",
  components: {
  },
  data() {
    return {
      userid: '',
      enterIDshow: true,
      bottom: 0,
      userInfo: [],
      userInfos: [],
      usershow: true,
      showpopups: false,
      quantityShow: false,
      quantity: '',
      transferShow: false,
      userhied: false,
      myDiamond: 0,
      password: '',//密码
      passwords: '',//实际密码
      iPassword: '',//初始密码
      tPasswords: '',//实际初始密码
      cPassword: '',//确认密码
      cPasswords: '',//实际确认密码
      passwordShow: true,
      pawsswordimg: hide,//隐藏显示图标
      pawsswordimgtype: false,
      seen: true,
      iPasswordShow: true,
      passworderr: false,//密码错误
      passwordyes: false,//是否成功
      keySources: [],
      zzConfirmShow: false,//转账确认弹窗
      transferyes: false,//转账成功弹窗
      walletInfo: [],//钱包信息
      token: null,
      uid: null,
      headers: null,
        // token: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1aWQiOiJNakk1TWpndyIsInh5eiI6Ik5EZzVRell5T0RZdE5URXpOQzAwUWtNeExUaEdRVEl0TkRFNFJEZEJPVVpEUmpoQyIsImV4cCI6MTkyODgxMDQ0OX0.sVjgOg3rnJVT62rG-ILduOXAhncnZUODHQZrR0XANas',
        // uid: 229280,
        // headers: [
        //   'Content-Type:' + 'application/json; charset=utf-8',
        //   'deviceId:' + '489C6286-5134-4BC1-8FA2-418D7A9FCF8B',
        //   'lang:' + 'zh_CN',
        //   'version:' + '2.2.0',
        //   'os:' + '1',
        //   'model:' + 'M2011K2C',
        //   'fid:' + '0',
        // ],
      zfpassworderr: false,
      // 组织重复点击弹窗
      stopRepeatFlag: false
    };
  },
  created() {
    // this.$completes.loadErudaJs()
    //调取原生方法
    this.$completes.complete("getUserInfo");
    window.getUserInfo = this.getUserInfo
    window['getUserInfo'] = (data) => {
      this.getUserInfo(data);
    }
    document.getElementsByTagName("html")[0].style.padding = "0 16px 0 16px";
  },
  mounted() {
    // this.$i18n.locale = 'ar'
    // document.querySelector('html').setAttribute('lang', this.$i18n.locale);
  },
  filters: {
    //数字格式化
    playvolume: function (arg) {
      if (arg > 1000) {
        return arg.toLocaleString()
      }
      else {
        return arg
      }
    },
  },
  watch: {
    'iPassword': function (val, oldV) {
      if (this.pawsswordimgtype) {
        if (val != this.tPasswords) {
          if (oldV.length > val.length) {
            this.tPasswords = this.tPasswords.substring(0, this.tPasswords.length - 1)
          } else {
            this.tPasswords = val
          }
        }
      }
      else {
        this.iPassword = this.iPassword.replace(/./g, '*');
        if (oldV.length > val.length) {
          //进行删除操作   this.form.password就是定义在data中用来存实际密码的变量
          this.tPasswords = this.tPasswords.substring(0, this.tPasswords.length - 1)
        } else {
          this.tPasswords += val.replace(/[*]/g, "");
        }
      }
    },
    'cPassword': function (val, oldV) {
      if (this.pawsswordimgtype) {
        if (val != this.cPasswords) {
          if (oldV.length > val.length) {
            this.cPasswords = this.cPasswords.substring(0, this.cPasswords.length - 1)
          } else {
            this.cPasswords = val
          }
        }
      }
      else {
        this.cPassword = this.cPassword.replace(/./g, '*');
        if (oldV.length > val.length) {
          //进行删除操作   this.form.password就是定义在data中用来存实际密码的变量
          this.cPasswords = this.cPasswords.substring(0, this.cPasswords.length - 1)
        } else {
          this.cPasswords += val.replace(/[*]/g, "");
        }
      }
    },
    'password': function (val, oldV) {
      if (this.pawsswordimgtype) {
        if (val != this.passwords) {
          if (oldV.length > val.length) {
            this.passwords = this.passwords.substring(0, this.passwords.length - 1)
          } else {
            this.passwords = val
          }
        }
      }
      else {
        this.password = this.password.replace(/./g, '*');
        if (oldV.length > val.length) {
          //进行删除操作   this.form.password就是定义在data中用来存实际密码的变量
          this.passwords = this.passwords.substring(0, this.passwords.length - 1)
        } else {
          this.passwords += val.replace(/[*]/g, "");
        }
      }
    },

  },

  methods: {
    //密码显示隐藏
    passwordShowh(idx) {
      this.pawsswordimgtype = !this.pawsswordimgtype
      if (this.pawsswordimgtype) {
        this.pawsswordimg = show
        if (idx == 1) {
          this.iPassword = this.tPasswords
        }
        else if (idx == 2) {
          this.cPassword = this.cPasswords
        }
        else {
          this.password = this.passwords
        }
      }
      else {
        if (idx == 1) {
          this.iPassword = this.iPassword.replace(/./g, '*');
        }
        else if (idx == 2) {
          this.cPassword = this.cPassword.replace(/./g, '*');
        }
        else {
          this.password = this.password.replace(/./g, '*');
        }
        this.pawsswordimg = hide
      }
    },

    //设置密码
    pwsetup() {
      if (this.iPassword != '' && this.iPassword.length >= 4) {
        this.iPasswordShow = false
        this.pawsswordimgtype = false
        this.pawsswordimg = hide

      }
      else {
        Toast(this.$t('transfer.contact_content36'))
      }

    },

    //序列帧
    xuliezheng(index) {
      var keySourceszi11 = [],
        keyIntervalzi11 = 0;
      for (var i = 0; i <= 25; i++) {
        keySourceszi11.push(require('../../assets/transfer/gou/gou_' + i + ".png"));
      }
      zi11(keySourceszi11, loadAnimatezi11());
      function zi11(sources, callback) {
        let loadedImages = 0;
        let numImages = 0;
        let images = [];
        numImages = sources.length;
        for (let i = 0, len = sources.length; i < len; i++) {
          images[i] = new Image();
          images[i].src = sources[i];
        }
      };
      function loadAnimatezi11() {
        if (index == 1) {
          playKeyframesAnimationzi11($("#zi11"), keySourceszi11, 60);
        }
        else {
          playKeyframesAnimationzi11($("#zi11s"), keySourceszi11, 60);
        }
      };
      function playKeyframesAnimationzi11(ele, keySourceszi11, keyTime) {
        keySourceszi11.forEach(function (item, i) {
          let keyHtml = '<img class="sccgjls" src="' + item + '" >';
          ele.append(keyHtml);
        });
        let i = 0;
        keyIntervalzi11 = setInterval(function () {
          i++;
          if (i < keySourceszi11.length) {
            ele.find("img").eq(i).show().siblings().hide();
          }
        }, keyTime)
      }
    },
    getUserInfo(data) {
      let that = this
      this.userInfos = JSON.parse(data);
      this.headers = [
        'Content-Type:' + 'application/json; charset=utf-8',
        'deviceId:' + JSON.parse(data).deviceId,
        'lang:' + JSON.parse(data).lang,
        'version:' + JSON.parse(data).version,
        'os:' + JSON.parse(data).os,
        'model:' + JSON.parse(data).model,
        'fid:' + JSON.parse(data).fid
      ]
      this.token = JSON.parse(data).token
      this.uid = JSON.parse(data).userId
      let langs = this.userInfos.lang.substring(0, 2)
      let lang = this.userInfos.lang
      if (langs == 'zh') {
        if (lang == 'zh_TW' || lang == 'zh_HK') {
          this.$i18n.locale = 'tc'
        }
        else {
          this.$i18n.locale = 'zh'
        }
      }
      else if (langs == 'en') {
        this.$i18n.locale = 'en'
      }
      else if (langs == 'ar') {
        this.$i18n.locale = 'ar'
      }
      else if (langs == 'tr') {
        this.$i18n.locale = 'tr'
      }
      else if (langs == 'pt') {
        this.$i18n.locale = 'pt'
      }
      else if (langs == 'es') {
        this.$i18n.locale = 'es'
      }
      else {
        this.$i18n.locale = 'en'
      }
      document.querySelector('html').setAttribute('lang', this.$i18n.locale);
      document.title = this.$t('transfer.contact_content4')
      let userId = Cookies.get('userId')//取出用户信息
      if (userId != undefined) {
        this.userid = userId
        this.searchUser()
      }
    },

    //搜索用户
    searchUser() {
      let that = this
      Cookies.remove('userId')
      if (this.userid != '') {
        let area = ''
        let paramObjs = {
          option: 'get',
          host: this.$serviceIp + 'api/code/family/search?token=' + that.token + '&uid=' + that.uid + '&type=1&keyword=' + that.userid,
          data: '',
          header: JSON.stringify(this.headers),
        };
        this.$server.curlExec(paramObjs).then((data) => {
          if (data.code == 200) {
            if (data.data.personList != undefined) {
              for (let index = 0; index < language.length; index++) {
                if (data.data.personList[0].cc != undefined) {
                  if (data.data.personList[0].cc.toUpperCase() == language[index].abbreviate) {
                    if (this.$i18n.locale == 'zh' || this.$i18n.locale == 'tc') {
                      area = language[index].name
                    }
                    else if (this.$i18n.locale == 'en') {
                      area = language[index].enName
                    }
                    else {
                      area = language[index].selfName
                    }
                  }
                }
                else {
                  area = language[5].enName
                }
              }
              this.usershow = false
              this.userhied = true
              let dacn = '';
              if(data.data.personList[0].cc){
                dacn =  (data.data.personList[0].cc).toLowerCase()
              }
              this.userInfo = {
                id: data.data.personList[0].userId,
                username: data.data.personList[0].nickname,
                area: area,
                areaUrl: dacn != '' ? require('../../assets/transfer/flags-mini/' + dacn  + ".png") : '',
                avatar: data.data.personList[0].headIcon
              }
              Cookies.set('userId', data.data.personList[0].userId)//存储用户信息
            }
            else {
              Toast(this.$t('transfer.contact_content9'))
            }
          }
          else {
            Toast(data.message || this.$t('transfer.error'))
          }
        })
      }
    },
    //用户id为空
    userkong() {
      if (this.userid == '') {
        this.usershow = true
        this.userInfo = ''
        this.userhied = false
      }
    },

    chuandi(item) {
      this.$router.push({ name: 'record', query: { userInfo: JSON.stringify(item) } })
    },

    //确认用户信息 拿到钱包信息
    confirmUserinfo(userInfo) {
      console.log("156156156");
      let that = this
      let paramObjs = {
        option: 'get',
        host: this.$serviceIp + 'api/trade/receller/getRecellerWallet?token=' + that.token + '&uid=' + that.uid,
        data: '',
        header: JSON.stringify(this.headers),
      };
      this.$server.curlExec(paramObjs).then((data) => {
        if (data.code == 200) {
          if (data.data != undefined) {
            that.quantityShow = true
            that.enterIDshow = false
            that.transferShow = true
            that.usershow = false
            that.userhied = false
            this.walletInfo = data.data
            this.myDiamond = data.data.diamond
          }

        }
        else {
          Toast(data.message || this.$t('transfer.error'))
        }
      })
    },

    //跳转联系人
    lianxir() {
      this.$router.push({ path: '/addressBook' })
    },


    //清空密码
    qingkong(e) {
      if (e == 1) {
        if (this.iPasswordShow) {
          this.tPasswords = ''
          this.iPassword = ''
          this.$nextTick(() => {
            this.$refs.refiPassword.focus()
          })
        }
        else {
          this.passworderr = false
          this.cPassword = ''
          this.cPasswords = ''
          this.$nextTick(() => {
            this.$refs.refcPassword.focus()
          })
        }
      }
      if (e == 3) {
        this.password = ''
        this.passwords = ''
        this.zfpassworderr = false
        this.$nextTick(() => {
          this.$refs.refpassword.focus()
        })
      }
      if (e == 4 && this.password == '') {
        this.zfpassworderr = false
        this.passwords = ''
      }
    },

    transfers() {
      if (this.quantity != '') {
        if (this.quantity > this.myDiamond) {
          Toast(this.$t('transfer.contact_content13'))
        }
        else if (this.walletInfo.state == 1) {
          Toast(this.$t('transfer.contact_content21'))
        }
        else {
          if (this.walletInfo.isSetPwd == 0) {
            this.showpopups = true;
          }
          else {
            this.zzConfirmShow = true
          }
        }
      }
    },


    Confirm() {
      if (this.tPasswords != '') {
        if (this.tPasswords == this.cPasswords) {
          let that = this
          let paramObjs = {
            option: 'post',
            host: this.$serviceIp + 'api/trade/receller/setRecellerWalletPwd?token=' + that.token + '&uid=' + that.uid,
            data: {
              pwd: this.cPasswords
            },
            header: JSON.stringify(this.headers),
          };
          this.$server.curlExec(paramObjs).then((data) => {
            if (data.code == 200) {
              this.passworderr = false
              this.showpopups = false
              this.passwordyes = true
              this.pawsswordimgtype = false
              this.pawsswordimg = hide
              this.confirmUserinfo()
              setTimeout(() => {
                this.xuliezheng(1)
              }, 500);
            }
            else {
              Toast(data.message || this.$t('transfer.error'))
            }
          })
        }
        else {
          this.passworderr = true
        }
      }


    },

    zzConfirm() {
      this.passwordyes = false
      this.zzConfirmShow = true
    },

    //确认zhuanzhang
    ConfirmTransfer() {
      if (this.password != '') {
        let that = this
        let paramObjs = {
          option: 'post',
          host: this.$serviceIp + 'api/trade/receller/deal?token=' + this.token + '&uid=' + this.uid,
          data: {
            num: String(this.quantity),
            pwd: this.passwords,
            receiveId: this.userInfo.id,
          },
          header: JSON.stringify(this.headers),
        };
        this.stopRepeatFlag = true;
        this.$server.curlExec(paramObjs).then((data) => {
          if (data.code == 200) {
            this.zzConfirmShow = false
            this.pawsswordimgtype = false
            this.pawsswordimg = hide
            this.transferyes = true
            this.password = ''
            this.passwords = ''
            setTimeout(() => {
              this.xuliezheng(2)
            }, 500);
          }
          else if (data.code == 501505) {
            this.zfpassworderr = true//密码错误
          }
          this.stopRepeatFlag = false;
        })


      }


    },


  },

};



</script>

<style>
html[lang="ar"] .text-right {
  direction: rtl;
}
.stopRepeat{
  width: 100%;
  height: 100vh;
  position: fixed;
  top:0;
  left: 0;
  z-index: 99;
  background: rgba(0, 0, 0, 0.4);
}
.stopRepeat .loading{
  width: 60px;
  height: 60px;
  background: url('../../assets/transfer/loading.png') no-repeat;
  background-size: 60px 60px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  animation: rotate 1s linear infinite;
}
@keyframes rotate {
  from {
    transform: rotate(0deg); /* 开始角度 */
  }
  to {
    transform: rotate(360deg); /* 结束角度 */
  }
}
.flexs {
  display: flex;
  align-items: center;
}

/* .van-field__body {
        border-bottom: 1px solid #ccc;
    } */

html[lang="ar"] .van-field__control {
  text-align: right;
}

html[lang="ar"] .userInfo {
  text-align: right;
  padding-left: 0;
  padding-right: 0.2rem;
}

html[lang="ar"] .diqus {
  padding-left: 0;
  padding-right: 0.06rem;
}

html[lang="ar"] .myDiamond {
  margin-left: 0;
  margin-right: 0.136rem;
}

html[lang="ar"] .dian {
  margin: 0.1rem 0 0.6rem 0.07rem;
}

html[lang="ar"] .zhuanshis {
  padding-left: 0;
  padding-right: 0.13rem;
}

html[lang="ar"] .passworderr {
  text-align: right;
  padding: 0.1rem 0.2rem 0 0;
}

html[lang="ar"] .userid {
  direction: ltr;
}

html[lang="ar"] .van-cell {
  padding-left: 0;
  padding-right: 10px;
}

.paymentMethod .van-field__control {
  color: #000000;
  font-family: Regular;
  font-size: 0.22rem;
}

.van-field__control::-webkit-input-placeholder {
  color: #b0b6b6;
  font-family: Regular;
  font-size: 0.22rem;
}

.van-cell {
  position: initial;
  /* padding: 10px 0; */
  line-height: 12px;
  background: none;
  padding-left: 10px;
}

.avatar .van-icon__image {
  border-radius: 50%;
  width: 1.02rem;
  height: 1.02rem;
}

.avatar .van-image__img {
  border-radius: 50%;
  width: 1.02rem;
  height: 1.02rem;
}

.enterIDss .van-cell__value {
  border-bottom: 1px solid #f0f0f0;
  padding-bottom: 0.05rem;
}

.enterIDss .van-cell {
  position: initial;
  padding: 10px 0 0 0;
  line-height: 12px;
  background: none;
  padding-left: 0;
}

.account {
  color: #000000;
  font-size: 0.44rem;
  font-family: mediums;
  line-height: 0.52rem;
}

.tishi {
  color: #b0b6b6;
  font-size: 0.2rem;
  font-family: Regular;
  line-height: 0.25rem;
  padding: 0.196rem 0 0.68rem 0;
}

.enterID {
  color: #b0b6b6;
  font-size: 0.22rem;
  font-family: Regular;
}

.nextStep {
  font-size: 0.3rem;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  background: #5dc48a;
  color: #ffffff;
  text-align: center;
  margin: auto;
  padding: 0.3rem 20px;
  border-radius: 50px;
  font-family: mediums;
  line-height: 1;
  margin-top: 0.666rem;
}

.userInfobox {
  font-size: 0.2rem;
  background-color: #fff;
}

.useridv {
  color: #000000;
  font-size: 0.3rem;
  font-family: Regular;
}

.avatar {
  border-radius: 50%;
  width: 1.02rem;
}

.userInfo {
  padding-left: 0.2rem;
  text-align: left;
}

.userInfo .userid {
  color: #999999;
  font-size: 0.188rem;
  font-family: Regular;
  padding-bottom: 0.13rem !important;
  line-height: 1;
}

.userInfo .name {
  color: #010101;
  font-size: 0.27rem;
  font-family: mediums;
  line-height: 1;
  padding-bottom: 0.08rem;
}

.userInfo .diqus {
  font-family: mediums;
  padding-left: 0.06rem;
  font-size: 0.2rem;
}

.userInfo .guoqi {
  border-radius: 2px;
  height: 0.239rem;
}

.passwordtitle {
  font-size: 0.29rem;
  text-align: center;
  padding-top: 0.478rem;
  font-family: mediums;
  line-height: 1;
}

.passwordcontent {
  font-size: 0.2rem;
  text-align: center;
  padding-top: 0.4rem;
  padding: 0.47rem 16px 0 16px;
}

.passwordtishi {
  color: #b0b6b6;
  font-size: 0.2rem;
  font-family: Regular;
  text-align: left;
  line-height: 0.256rem;
  margin-top: 0.196rem;
}

.passworderr {
  color: #ff2626;
  font-size: 0.153rem;
  font-family: Regular;
  text-align: left;
  padding: 0.1rem 0 0 0.2rem;
}

.record {
  color: #5dc48a;
  font-size: 0.2rem;
  font-family: Regular;
}

.myDiamond {
  color: #000000;
  font-size: 0.2rem;
  font-family: Regular;
  margin-left: 0.136rem;
  line-height: 1;
}

.zsshu {
  color: #000000;
  font-size: 0.3rem;
  font-family: mediums;
  line-height: 1;
}

.titlebox {
  display: flex;
  margin-bottom: 0.26rem;
}

.dian {
  width: 0.07rem;
  height: 0.07rem;
  background: #5dc48a;
  border-radius: 50%;
  min-width: 0.07rem;
  min-height: 0.07rem;
  margin: 0.1rem 0.07rem 0.6rem 0;
}

.titleb {
  font-size: 0.25rem;
  font-family: mediums;
  font-weight: 400;
  color: #000000;
  line-height: 1;
  /* padding: 0.245rem 0 0 0; */
}

.paymentMethod {
  background: #f6f7fb;
  border-radius: 0.13rem;
  left: 0;
  right: 0;
  margin: auto;
  display: flex;
  align-items: center;
  height: 0.76rem;
}

.paymentMethod .van-cell {
  padding: 0;
  padding-left: 10px;
}

.van-field__button {
  display: flex;
  align-items: center;
}

.renanimation {
  width: 110%;
  height: 4.8rem;
  position: relative;
  left: -0.2rem;
}

.wenzi3 {
  width: 100%;
  margin-bottom: 0.196rem;
  position: relative;
  height: 1.6rem;
}

.sccgjls {
  position: absolute;
  top: 0;
  width: 1.6rem;
  height: 1.6rem;
  left: 0;
  right: 0;
  margin: auto;
}

.zhuanshis {
  color: #5dc48a;
  font-size: 0.3rem;
  font-family: Regular;
  padding-left: 0.13rem;
}

.pwtishi {
  color: #000000;
  text-align: center;
  font-size: 0.2rem;
  font-family: Regular;
  line-height: 0.256rem;
  margin-top: 0.196rem;
  margin: 0.2rem 1.4rem;
}
</style>